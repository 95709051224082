import React from "react";
import { Container } from "../layouts/container";
import { Grid, Typography } from "@mui/material";

export default () => {
  return (
    <Container>
      <Grid
        container
        flexDirection="column"
        sx={{
          minHeight: "100vh",
          justifyContent: "center",
        }}
      >
        <Grid item>
          <svg
            width="100%"
            height="20rem"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 128 128"
          >
            <path
              fill="#e4caa6"
              d="M79.709 6.737L95.163 10.877 90.505 28.264 75.05 24.123 79.709 6.737"
            />
            <path
              fill="#ceb797"
              d="M89.322 9.377L84.679 26.703 90.475 28.256 95.117 10.93 89.322 9.377"
            />
            <path
              fill="#6ec7b0"
              d="M91.3,25.4l-15.5-4.1c-1.1-0.3-2.2,0.3-2.399,1.4L71.8,28.5c-0.3,1.1,0.3,2.2,1.4,2.4l-8,30 c-7.8-1.8-15.8,2.9-17.9,10.7l-12.9,49l47.1,0.2l9.3-37.5C92.9,75.5,88.4,67.4,80.7,65.1l8-30c1.1,0.3,2.2-0.3,2.399-1.4l1.601-5.8 C93,26.8,92.3,25.7,91.3,25.4"
            />
            <path
              fill="#64b4a0"
              d="M75.548,64.714c4.817,3.56,7.236,9.861,5.551,15.986l-10.587,40.053l10.157,0.043L90.7,83.3 c0.983-3.619,0.735-7.331-0.862-10.364C85.667,65.015,75.548,64.714,75.548,64.714"
            />
            <path
              fill="#64b4a0"
              d="M82.837,33.54L74.82,63.458L80.2,64.9c0.139,0.037,0.277,0.075,0.414,0.115l8.017-29.923 L82.837,33.54"
            />
            <path
              fill="#fff"
              d="M49.8,108.8c-0.3,0-0.5,0-0.8-0.1c-1.6-0.4-2.5-2.1-2.1-3.7l8.2-30.6c0.4-1.6,2.1-2.5,3.7-2.1 c1.6,0.4,2.5,2.1,2.1,3.7l-8.2,30.6C52.3,107.9,51.1,108.8,49.8,108.8z"
            />
            <path
              fill="#454b54"
              d="M95.9 8L80.5 3.9c-1.6-.4-3.2.5-3.7 2.1l-3.4 12.6c-1.4.6-2.5 1.8-3 3.3l-1.6 5.8c-.3 1.3-.2 2.6.5 3.8.1.2.3.4.4.6L63 57.5c-8.4-.6-16.3 4.9-18.6 13.3l-13 48.6c-1.9-.5-3.6-1.6-4.8-3-.6-.7-1.4-1.1-2.3-1.1s-1.7.4-2.3 1.1c-1.8 2.2-4.6 3.4-7.6 3.4s-5.8-1.2-7.6-3.4c-1.1-1.3-3-1.4-4.2-.3-1.3 1.1-1.4 3-.3 4.2 3 3.5 7.4 5.5 12.2 5.5 3.7 0 7.1-1.2 9.9-3.3 2.8 2.2 6.2 3.3 9.9 3.3s7.1-1.2 9.9-3.3c2.8 2.2 6.2 3.3 9.9 3.3 3.6 0 7.1-1.2 9.8-3.3 2.8 2.2 6.3 3.5 10 3.5.1 0 .1 0 .2 0 3.7 0 7.3-1.3 10.1-3.5 2.8 2.1 6.2 3.3 9.8 3.3 1.7 0 3-1.3 3-3s-1.3-3-3-3c-3 0-5.8-1.2-7.6-3.4-.3-.3-.6-.6-1-.8L93.7 84c1.2-4.6.6-9.5-1.8-13.7-1.8-3.1-4.5-5.6-7.6-7.2l6.8-25.5c1.4-.6 2.5-1.8 3-3.3l1.6-5.8c.3-1.3.2-2.6-.5-3.8-.1-.2-.3-.4-.4-.6L98 11.7C98.5 10.1 97.5 8.4 95.9 8zM86.7 73.4c1.6 2.8 2 6 1.2 9.1l-9.8 36.7c-1.3.6-2.7.9-4.2.9h-.1c-2.9 0-5.7-1.3-7.5-3.4l-.2-.2c-.6-.7-1.4-1.1-2.3-1.1l0 0c-.9 0-1.7.4-2.3 1.1-1.8 2.2-4.6 3.4-7.6 3.4s-5.8-1.2-7.6-3.4c-.6-.7-1.4-1.1-2.3-1.1s-1.7.4-2.3 1.1c-1.1 1.3-2.5 2.2-4.1 2.8l12.5-46.8c1.7-6.4 8.3-10.2 14.7-8.5l14.5 3.9C82.5 68.6 85.1 70.6 86.7 73.4zM78.5 61.3l-9.7-2.6 6.5-24.1 1.3.4 8.3 2.2L78.5 61.3zM88.5 31.9l-6.8-1.8L75 28.3l1-3.9L89.5 28 88.5 31.9zM89.1 21.7l-9.7-2.6 2.3-8.7 9.7 2.6L89.1 21.7zM123.5 121.3c-1.7 0-3-1.3-3-3s1.3-3 3-3l0 0c1.7 0 3 1.3 3 3S125.2 121.3 123.5 121.3z"
            />
            <path
              fill="#454b54"
              d="M93.8,125.8c-4.7,0-9.2-2-12.2-5.5c-1.1-1.3-0.9-3.2,0.3-4.2c1.3-1.1,3.2-0.9,4.2,0.3 c1.8,2.2,4.6,3.4,7.6,3.4s5.8-1.2,7.6-3.4c0.6-0.7,1.4-1.1,2.3-1.1l0,0c0.9,0,1.7,0.4,2.3,1.1c1.8,2.1,4.5,3.3,7.3,3.4 c1.7,0,3,1.4,2.9,3.1c0,1.6-1.4,2.9-3,2.9H113c-3.5-0.1-6.8-1.3-9.5-3.3C101,124.6,97.5,125.8,93.8,125.8z"
            />
          </svg>
        </Grid>
        <Grid item>
          <Typography
            textAlign="center"
            variant="h1"
            sx={{
              fontSize: {
                xs: "5rem",
                sm: "6rem",
              },
            }}
          >
            Perdu ?
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
